<template>
  <div>
    <v-row class="ma-0 mb-1">
      <v-col cols="4">
        <v-text-field
          :background-color="search ? 'serenade' : ''"
          v-model.trim="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          hide-details
          dense
          clearable
          :placeholder="'search'|localize"
        />
      </v-col>
      <v-spacer/>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="requestList"
      :loading="loading"
      item-key="id + transport_mode"
      loader-height="2"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="'noDataAvailable'|localize"
      hide-default-footer
    >
      <template v-slot:item.offer_reference="{ item }">
        {{ item['offer_reference'] ? item['offer_reference'] : '--' }}
      </template>
      <template v-slot:item.proposal="{ item }">
        {{ item['proposal'] }} <i class="text-body-2">{{ item['currency'] }}</i>
      </template>
      <template v-slot:item.lead_time="{ item }">
        {{ item['lead_time'] }} {{ leadTimeDays(item['lead_time']) }}
      </template>
      <template v-slot:item.deadline_for_booking="{ item }">
        <div v-if="item['deadline_for_booking'].trim()" v-html="$options.filters.formatDate(item['deadline_for_booking'])"/>
        <div v-else>--</div>
      </template>
      <template v-slot:item.latest_collection_time="{ item }">
        <div v-if="item['latest_collection_time'].trim()" v-html="$options.filters.formatDate(item['latest_collection_time'])"/>
        <div v-else>--</div>
      </template>
      <template v-slot:item.valid_to="{ item }">
        <div v-html="$options.filters.formatDate(item['valid_to'])"/>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="STATUS_COLORS[item.status]" size="20" class="mr-2">{{ STATUS_ICONS[item.status] }}</v-icon>
          <span>{{ item.status|localize }}</span>
        </div>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-tooltip v-if="item.comment" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="success" size="20">mdi-comment</v-icon>
          </template>
          <span>{{ item.comment }}</span>
        </v-tooltip>
        <v-tooltip v-if="item['reopen_comment']" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="warning" size="20">mdi-comment</v-icon>
          </template>
          <div>
            <div class="mb-2"><strong>{{ 'reopenComment'|localize }}:</strong></div>
            {{ item['reopen_comment'] }}
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.attachments="{ item }">
        <v-tooltip v-for="fileData in item['attachments']" top color="black" max-width="320" :key="fileData['attachment_name']">
          <template v-slot:activator="{ on, attrs }">
            <a :href="fileData['attachment_link']" target="_blank" style="text-decoration: none">
              <v-icon v-bind="attrs" color="success" v-on="on" size="20">mdi-file</v-icon>
            </a>
          </template>
          <span>{{ fileData['attachment_name'] }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.buttons="{ item }">
        <v-btn
          v-if="item.status === STATUSES.BOOKED"
          class="justify-center btn-fixed-width"
          rounded
          fab
          x-small
          depressed
          color="success"
          :to="{name: 'booking-summary', query: {request_id: requestId, proposal_id: item.id, mode: transportMode}}"
        >
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20">mdi-book-open-variant</v-icon>
            </template>
            <span>{{ 'bookingSummaryOpen'|localize }}</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-if="isProposalsEditing" v-slot:item.settings="{ item }">
        <div v-if="item.status === STATUSES.ACTION_REQUIRED" class="text-center py-1">
          <v-progress-circular
            v-if="confirms.includes(item.id)"
            indeterminate
            :width="1"
            :size="20"
          />
          <template v-else>
            <v-btn
              class="mb-1 middle-button"
              depressed
              color="success"
              block
              lo
              @click="confirmProposal(item.id)"
            >
              {{ 'confirmPrice'|localize }}
            </v-btn>
            <v-btn
              class="middle-button"
              depressed
              color="grey lighten-2"
              block
              @click="editProposal(item)"
            >
              {{ 'updatePrice'|localize }}
            </v-btn>
          </template>
        </div>
        <div v-else class="text-right">
          <v-menu left transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" size="45" tile>
                <v-icon size="20">mdi-dots-vertical</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link @click="editProposal(item)">
                <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="copyProposal(item)">
                <v-list-item-title>{{ 'copy'|localize }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-pagination
      color="primary"
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      class="py-6"
      :disabled="loading"
      @input="requestList = [];getProposals();"
    />
  </div>
</template>

<script>
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import {STATUSES, STATUS_COLORS, STATUS_ICONS} from '@/enums/statuses';

  export default {
    name: 'CompanyQuotes',
    props: ['requestStatus'],

    data() {
      return {
        STATUSES,
        STATUS_COLORS,
        STATUS_ICONS,
        transportMode: this.$router.currentRoute.query.mode,
        requestId: this.$router.currentRoute.query.request_id,
        loading: true,
        debouncedSearch: '',
        debounceTimeout: null,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        requestList: [],
        confirms: []
      };
    },

    watch: {
      options: {
        handler() {
          this.clearRequestList();
          this.getProposals();
        },
        deep: true,
      },
      search() {
        this.clearRequestList();
        this.getProposals();
      },
    },

    computed: {
      headers() {
        let localHeaders = [
          {text: localize('quoteReference'), value: 'offer_reference', width: 75, sortable: false},
          {text: localize('quotation'), value: 'proposal', width: 110},
          {text: localize('leadTime'), value: 'lead_time', width: 90},
          {text: localize('deadlineForBooking'), value: 'deadline_for_booking', width: 140},
          {text: localize('latestTimeCollection'), value: 'latest_collection_time', width: 150},
          {text: localize('quoteValidTo'), value: 'valid_to', width: 160},
          {text: localize('comments'), value: 'comment', align: 'center', width: 40, sortable: false},
          {text: localize('status'), value: 'status', width: 120},
          {text: localize('uploadedFiles'), value: 'attachments'},
          {text: '', value: 'buttons', align: 'center', width: 50, sortable: false},
          {text: '', value: 'settings', sortable: false, width: 50},
        ];
        if (!this.isProposalsEditing) {
          localHeaders = localHeaders.filter((item) => item.value !== 'settings');
        }
        if (!this.isAnyButtons) {
          localHeaders = localHeaders.filter((item) => item.value !== 'buttons');
        }
        return localHeaders;
      },
      userData() {
        return this.$store.getters.userData;
      },
      isProposalsEditing() {
        return (
          (this.requestStatus === STATUSES.NEW) ||
          (this.requestStatus === STATUSES.PENDING) ||
          (this.requestStatus === STATUSES.NEED_RESPONSE)
        )
      },
      isAnyButtons() {
        const buttons = this.requestList.filter((item) => item.status === STATUSES.BOOKED)
        return this.requestList.length > 0 && buttons.length > 0;
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      editProposal(request) {
        this.$router.push({name: 'create-quote', query: {request_id: this.requestId, mode: this.transportMode, proposal_id: request.id, action: 'edit'}});
      },
      copyProposal(request) {
        this.$router.push({name: 'create-quote', query: {request_id: this.requestId, mode: this.transportMode, proposal_id: request.id, action: 'copy'}});
      },
      clearRequestList() {
        this.requestList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      queryData() {
        let queryData = {};
        queryData['request_id'] = this.requestId;
        queryData['transport_mode'] = this.transportMode;
        queryData['company_id'] = this.userData.company.id;
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.search && (queryData['search'] = this.search);
        return queryData;
      },
      setProposalsData(requestsData) {
        this.requestList = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      async getProposals() {
        try {
          this.loading = true;
          let proposalsData = await api.getCompanyProposals(this.queryData());
          this.setProposalsData(proposalsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      leadTimeDays(item) {
        return item > 1 ? localize('days') : localize('day')
      },
      async confirmProposal(proposal_id) {
        this.confirms.push(proposal_id);
        try {
          await api.confirmCompanyProposal(proposal_id);
          let proposalsData = await api.getCompanyProposals(this.queryData());
          this.setProposalsData(proposalsData);
          this.confirms = this.confirms.filter(queueId => queueId !== proposal_id)
        } catch (e) {}
      },
    },
  }
</script>

<style lang="scss" scoped>
  .middle-button {
    height: 30px !important;
  }
</style>
